import React from "react"

import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"
import Fade from 'react-reveal/Fade'

const IndexPage = ({ data: { allPrismicCase } }) => (
  <Layout>
    <SEO title="Home" />
      <div className={'c-intro'}>
          <h1 className={'c-intro__title'}>Hallo, <span role="img" aria-label="Zeer vrolijk wuivend handje">👋</span>
          <br />Ik ben een senior UX / UI designer met reeds 12 jaar ervaring.</h1>
          <div className={'c-intro__description'}>
            <p>Mijn digitale passie omvat het brede UX spectrum; van strategie, research, analyse, wireframing, prototyping, user testing tot UI design in een agile omgeving.</p>
          </div>
      </div>
      <div className={'o-case'}>
        {allPrismicCase.edges.map(i =>
          <Link to={ "/" + i.node.uid } className={'o-case__link'}>
            <Fade bottom distance={'100px'} duration={800} key={i.node.uid}>
              <div>
                <div className={'o-case__link__image'}>
                  <Image imagePath={ i.node.uid + "-teaser1.png" } imageAlt={ i.node.data.client.text } />
                </div>
                <div className={'o-case__link__card'}>
                  <div className={'o-case__link__description'}>
                    <h3>{ i.node.data.client.text }</h3>
                    <ul>
                      {i.node.data.role.map(data =>
                        <li key={data.title.text}>
                          { data.title.text }
                        </li>
                      )}
                    </ul>
                  </div>
                  <div className={'link link--arrow'}>Meer info</div>
                </div>
              </div>
            </Fade>
          </Link>
        )}
      </div>
  </Layout>
)

export default IndexPage

export const query = graphql`
{
  allPrismicCase (sort: {fields: data___ordernumber}) {
    edges {
      node {
        uid
        data {
          client {
            text
          }
          role {
            title {
              text
            }
          }
        }
      }
    }
  }
}
`
